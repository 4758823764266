/*
 * Copyright 2018, Jean Traullé <jtraulle@opencomp.fr>
 *
 * @copyright Copyright 2018, Jean Traullé <jtraulle@opencomp.fr>
 *
 */

// Before any ajax request, we reset the timer for session expiration modale
$(document).ajaxSend(function () {
    window.dateLastSessionTimeoutReset = new Date();
});

// If despite refreshing the session, request failed with
// 401 or 403 errors, then user has not enabled rememberMe
// feature and is disconnected, redirecting to login page
$(document).ajaxError(function (event, jqXHR) {
    if ((jqXHR.status === 403 || jqXHR.status === 401)) {
        window.location.replace("/login?redirect=" + encodeURI(window.location.href));
    }
});
